@keyframes rideTillYouDie {
  0% {
    bottom: 0px;
  }

  50% {
    bottom: 1px;
  }

  100% {
    bottom: 0px;
  }
}

.paragraph {
  color: inherit;
  font-weight: 500;
  line-height: 1.8;

  a {
    color: var(--clr-primary);
  }

  p + p,
  p + ul,
  ul + p {
    margin-top: 2rem;
  }

  ul {
    padding-left: 1.5rem;
    list-style-type: disc;
  }

  &.regular {
    font-size: var(--p-reg-size);
  }

  &.large {
    font-weight: 600;
    font-size: var(--p-large-size);
  }
}
