.abstractButton {
  appearance: none;
  border: none;
  background: none;
  z-index: 1;
  max-width: 100%;
  -webkit-tap-highlight-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  cursor: pointer;
  font-size: 1em;
  padding: 0;
}
